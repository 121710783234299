import { FaEnvelope, FaInstagram, FaYoutube } from 'react-icons/fa';

const Footer = () => (
    <footer className="bg-gray-800 text-gray-100 py-8">
        <div className="container mx-auto px-4 text-center">
            <p className="mb-4">© 2024 Alexandre Rolim de Sá. Todos os direitos reservados.</p>
            <p>Alexandre Rolim Sociedade Individual de Advocacia</p>
            <p>CNPJ n. 51.391.834/0001-06</p>
            <div className="flex justify-center space-x-6 mt-4">
                <a href="https://youtube.com/@alexandrerolimadvocacia?si=g5ysX16jMa5pBy76" className="hover:text-gray-300">
                    <FaYoutube size={24} />
                </a>
                <a href="mailto:alexandrerolim@oabce.adv.br" className="hover:text-gray-300">
                    <FaEnvelope size={24} />
                </a>
                <a href="https://www.instagram.com/adv.alexandrerolim?igshid=YzcxN2Q2NzY0OA%3D%3D" className="hover:text-gray-300">
                    <FaInstagram size={24} />
                </a>
            </div>
        </div>
    </footer>
);

export default Footer;
