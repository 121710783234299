const ServiceCard = ({ service, Icon, description }) => (
    <div className="bg-white p-6 rounded-lg shadow-md text-center">
        <div className="text-4xl mb-4 text-gray-800">
            <Icon className="mx-auto" />
        </div>
        <h3 className="text-xl font-semibold mb-2">{service}</h3>
        <p className="text-gray-600">
            {description}
        </p>
    </div>
);

export default ServiceCard;
