import Logo from '../../assets/Logo_alexandre.webp';
import { useCallback } from 'react';

const Navbar = () => {
    const scrollToSection = useCallback((sectionId) => (event) => {
        event.preventDefault();
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    return (
        <nav className="bg-gray-800 text-gray-100">
            <div className="container mx-auto px-4 py-4 flex justify-center md:justify-between items-center">
                <div className="md:flex-none flex-1 flex justify-center md:justify-start">
                    <img src={Logo} alt="Logo Alexandre Rolim de Sá" className="h-12" />
                </div>
                <div className="hidden md:flex space-x-4">
                    <a href="#servicos" onClick={scrollToSection('servicos')} className="hover:text-gray-300">Serviços</a>
                    <a href="#sobre" onClick={scrollToSection('sobre')} className="hover:text-gray-300">Sobre</a>
                    <a href="#depoimentos" onClick={scrollToSection('depoimentos')} className="hover:text-gray-300">Depoimentos</a>
                    <a href="#contato" onClick={scrollToSection('contato')} className="hover:text-gray-300">Contato</a>
                    <a href="#entrevistas" onClick={scrollToSection('entrevistas')} className="hover:text-gray-300">Entrevistas</a>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;