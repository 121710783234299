import ServiceCard from './ServiceCard';
import { FaGavel, FaBriefcase, FaUserShield } from 'react-icons/fa';

const ServicesSection = () => (
    <section id="servicos" className="py-24 bg-gray-100">
        <div className="container mx-auto px-4">
            <h2 className="text-3xl font-light mb-12 text-center text-gray-800">Atuação</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <ServiceCard
                    service="Direito Civil"
                    Icon={FaGavel}
                    description="Atuamos em diversas áreas do direito civil, como direito do consumidor, direito contratual, responsabilidade civil, dentre outras."
                />
                <ServiceCard
                    service="Direito Trabalhista"
                    Icon={FaBriefcase}
                    description="Defendemos os interesses de trabalhadores e empregadores, assegurando relações de trabalho justas e equilibradas."
                />
                <ServiceCard
                    service="Direito Previdenciário"
                    Icon={FaUserShield}
                    description="Garantimos seus direitos previdenciários, auxiliando em aposentadorias, pensões e benefícios do INSS com expertise e dedicação."
                />
            </div>
        </div>
    </section>
);

export default ServicesSection;