import { FaGithub } from 'react-icons/fa';

const CreditsFooter = () => {
    return (
        <footer className="bg-gray-900 text-gray-100 py-4 text-center">
            <div className="container mx-auto flex justify-center items-center space-x-2">
                <p className="text-sm">
                    Desenvolvido por{' '}
                    <a
                        href="https://github.com/Leorlsa"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-semibold hover:underline"
                    >
                        @Leorlsa
                    </a>
                </p>
                <a
                    href="https://github.com/Leorlsa"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaGithub className="w-5 h-5" />
                </a>
            </div>
        </footer>
    );
};

export default CreditsFooter;
