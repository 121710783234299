import {FaChevronLeft, FaChevronRight, FaStar} from "react-icons/fa";
import {useState} from "react";

export default function TestimonialSection() {
    const testimonials = [
        {
            name: "Jordana Valentim",
            content:
                "Ao Doutor Alexandre e sua equipe o meu muito obrigada, pela disponibilidade, atenção, todas as explicações e paciência. Tornou um processo estressante, bem mais fácil e com objetivo desejado garantido no final. Mais uma vez, muito OBRIGADA",
            avatar: "https://lh3.googleusercontent.com/a-/ALV-UjVTH2g6OgKsRaTtOGqgddDF8S3xYkpEFOQXOo1knjbg0jDhp5Q=s40-c-rp-mo-br100",
            rating: 5,
        },
        {
            name: "Antonio Neto",
            content: "Excelente trabalho.. Um grande profissional 👏🏽🙌🏾",
            avatar: "https://lh3.googleusercontent.com/a-/ALV-UjXazg1h3QMqHqAseT-KVIDzWBT-HsTccoyi70L-KrkI2YgfU6I=s40-c-rp-mo-br100",
            rating: 5,
        },
        {
            name: "Eridan Mourao",
            content:
                "Obrigada pelo seu destaque e pela sua competência excepcional ao resolver a causa de forma eficiente. Sua atenção meticulosa a cada detalhe reflete um compromisso notável com a excelência profissional.",
            avatar: "https://lh3.googleusercontent.com/a-/ALV-UjUESvHtumY4pYojSoSBcsi32DgxSyKUNRdFj41LtOg6-wsfycYc2g=s40-c-rp-mo-br100",
            rating: 5,
        },
        {
            name: "Ana Carolina Feitosa",
            content:
                "Dr Alexandre, sempre muito ágil e resolutivo, cumpre prazos e altamente organizado. É um excelente profissional e estou extremamente satisfeita com o seu trabalho.",
            avatar: "https://lh3.googleusercontent.com/a-/ALV-UjV4obdpbvWSVGymwXDoXte5di2vaeZAalMfRVyvPfisjmWJ5Htl=s40-c-rp-mo-br100",
            rating: 5,
        },
        {
            name: "Marina Arrais",
            content:
                "Dr Alexandre super profissional e prático. Resolveu tudo pelo WhatsApp, como não tenho muito tempo facilitou muito minha vida. Audiência deferida ao meu favor! Fiquei extremamente satisfeita com a praticidade e resolução do caso!",
            avatar: "https://lh3.googleusercontent.com/a-/ALV-UjX6jgcgch3bOFGg1wADFRhy3852y1jSxBvc4GoX7J-1pSUQnCLv=s40-c-rp-mo-br100",
            rating: 5,
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const nextTestimonial = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    const prevTestimonial = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
    };

    return (
        <div id="depoimentos" className="w-full max-w-4xl mx-auto px-6 py-24 gap-6">
            <h2 className="text-3xl font-light text-center text-gray-800 mb-12">Depoimentos</h2>
            <div className="border-none shadow-lg p-6">
                <div className="flex items-center justify-between mb-4">
                    <img
                        src={testimonials[currentIndex].avatar}
                        alt={testimonials[currentIndex].name}
                        className="w-10 h-10 rounded-full"
                    />
                    <div className="flex gap-1">
                        {[...Array(5)].map((_, i) => (
                            <FaStar
                                key={i}
                                className={`w-5 h-5 ${
                                    i < testimonials[currentIndex].rating ? "text-yellow-400" : "text-gray-300"
                                }`}
                            />
                        ))}
                    </div>
                </div>
                <blockquote className="text-lg italic mb-4">"{testimonials[currentIndex].content}"</blockquote>
                <div className="font-semibold">{testimonials[currentIndex].name}</div>
            </div>
            <div className="flex justify-center mt-6 gap-4">
                <button
                    className="p-2 border rounded-full hover:bg-gray-200"
                    onClick={prevTestimonial}
                    aria-label="Depoimento anterior"
                >
                    <FaChevronLeft className="h-4 w-4"/>
                </button>
                <button
                    className="p-2 border rounded-full hover:bg-gray-200"
                    onClick={nextTestimonial}
                    aria-label="Próximo depoimento"
                >
                    <FaChevronRight className="h-4 w-4"/>
                </button>
            </div>
        </div>
    );
}