import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { IMaskInput } from 'react-imask';
import Button from './Button';
import Wpp from '../../assets/wpp.svg';
import UserIcon from '../../assets/user.svg';
import { FaPaperPlane } from 'react-icons/fa';


const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState('');

    const isFormFilled = name && email && whatsapp && message;

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!recaptchaToken) {
            setStatus('recaptchaError');
            return;
        }

        fetch('https://formspree.io/f/mnnaleqv', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
                email: email,
                whatsapp: whatsapp,
                message: message,
                'g-recaptcha-response': recaptchaToken,
            }),
        }).then((response) => {
            if (response.ok) {
                setStatus('success');
                setName('');
                setEmail('');
                setWhatsapp('');
                setMessage('');
                setRecaptchaToken(''); // Resetando o token do reCAPTCHA
            } else {
                setStatus('error');
            }
        }).catch(() => {
            setStatus('error');
        });
    };

    return (
        <section id="contato" className="py-24 bg-[#1f2937]">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-light mb-8 text-center text-gray-100">Entre em contato</h2>
                {status === 'success' && (
                    <div className="mb-4 text-center text-green-400 font-semibold">
                        Mensagem enviada com sucesso!
                    </div>
                )}
                {status === 'error' && (
                    <div className="mb-4 text-center text-red-400 font-semibold">
                        Ocorreu um erro ao enviar sua mensagem. Tente novamente.
                    </div>
                )}
                {status === 'recaptchaError' && (
                    <div className="mb-4 text-center text-red-400 font-semibold">
                        Por favor, complete o reCAPTCHA.
                    </div>
                )}
                <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
                    <div className="mb-4 relative">
                        <img src={UserIcon} alt="User Icon" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-[#1f2937]" />
                        <input
                            type="text"
                            placeholder="Seu nome"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-gray-500 placeholder-gray-500 text-gray-800"
                        />
                    </div>

                    <div className="mb-4 relative">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#1f2937]">@</span>
                        <input
                            type="email"
                            placeholder="Seu e-mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-gray-500 placeholder-gray-500 text-gray-800"
                        />
                    </div>

                    <div className="mb-4 relative">
                        <img src={Wpp} alt="WhatsApp" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-[#1f2937]" />
                        <IMaskInput
                            mask="(00) 00000-0000"
                            placeholder="Seu WhatsApp"
                            value={whatsapp}
                            onAccept={(value) => setWhatsapp(value)}
                            required
                            className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-gray-500 placeholder-gray-500 text-gray-800"
                        />
                    </div>

                    <div className="mb-4">
                        <textarea
                            placeholder="Sua mensagem"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                            className="w-full pl-4 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-gray-500 placeholder-gray-500 text-gray-800"
                        />
                    </div>

                    {isFormFilled && (
                        <div className="mb-4">
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                onChange={(token) => setRecaptchaToken(token)}
                            />
                        </div>
                    )}

                    <Button
                        type="submit"
                        style={{
                            backgroundColor: '#f3f4f6',
                            color: '#1f2937',
                            padding: '8px 16px',
                            fontWeight: 'normal',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '8px', // Espaço entre o texto e o ícone
                            transition: 'transform 0.3s ease-in-out',
                            border: 'none'
                        }}
                        onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
                        onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                    >
                        Enviar <FaPaperPlane />

                    </Button>
                </form>
            </div>
        </section>
    );
};

export default ContactForm;
